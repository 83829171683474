<template>
  <div>
    <MasterHeader title="Dashboard" />
    <sign-up-modal v-if="hasNewSignupFlow" />
    <div class="dashboard">
      <div class="container container-wide">
        <div class="row">
          <div class="col-12">
            <div class="widget">
              <div class="widget-splash splash-line"></div>
              <div class="widget-header">
                <h4>Welcome</h4>
                <div class="widget-navigation">
                  <router-link :to="'/user/profile-edit'" class="widget-nav"
                    ><i class="fas fa-user-edit"></i>Edit profile</router-link
                  >
                </div>
              </div>
              <div class="widget-body profile">
                <div class="row" v-if="user.role === 'Student'">
                  <div class="col-12 col-md-8">
                    <h4>
                      {{ user.firstName }} {{ user.lastName }}
                      <!-- <span class="badge">student</span> -->
                    </h4>
                    <div class="">
                      {{ user.institution.name }} | {{ user.faculty }} |
                      {{ user.degree }} | {{ user.area }} | Graduating
                      {{ user.graduatingSemester }}
                    </div>
                  </div>
                  <div class="col-12 col-md-4 text-right">
                    <img
                      :src="
                        '/assets/institutions/' + user.institution.id + '.png'
                      "
                      class="img-fluid"
                      style="max-width: 300px"
                      :title="user.institution.name"
                    />
                  </div>
                </div>

                <div class="row mb-3" v-if="user.role === 'Professional'">
                  <div class="col-12 col-md-8">
                    <h4>{{ user.firstName }} {{ user.lastName }}</h4>
                    <!-- <div class="">
                                         {{ user.favorites.length}} bookmarks
                                    </div> -->
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-3">
                    Email
                    <div class="headline">{{ user.email }}</div>
                  </div>
                  <div
                    class="col-12 col-md-3"
                    v-if="user.role === 'Professional'"
                  >
                    Phone
                    <div class="headline">{{ user.phoneNumber }}</div>
                  </div>
                  <div class="col-12 col-md-6">
                    Interests
                    <div class="headline">{{ user.interests.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-12" style="background-color: green;" v-if="this.$route.params.widget === 'welcome'">
                    welcome NEW user. -> start guide
                </div> -->

          <!-- PROFESSIONAL - YOU HAVE BEEN INVITED TO A COMPANY -->
          <div
            class="col-12 col-md-6"
            v-if="
              user.role === 'Professional' &&
              companyInvites.length > 0 &&
              user.company.length === 0
            "
          >
            <div class="widget">
              <div
                class="widget-splash"
                style="background-image: url('/assets/company-invitation.jpg')"
              ></div>
              <div class="widget-header">
                <h4>Invite</h4>
                <!-- <div class="widget-navigation">
                                <router-link :to="'/company/new'" class="widget-nav"><i class="fas fa-plus"></i>Register</router-link>
                            </div> -->
              </div>
              <div class="widget-body">
                <div
                  v-for="(invite, index) in companyInvites"
                  :key="index"
                  class="erow row d-flex"
                >
                  <div class="col-1 align-self-center">
                    <i class="fas fa-envelope"></i>
                  </div>
                  <div class="col-5 align-self-center">
                    <div class="">
                      {{ invite.invites.sender }} invites you to join
                    </div>
                    <div class="headline">{{ invite.invites.companyName }}</div>
                  </div>
                  <div class="col-6 align-self-center text-right">
                    <button
                      type="button"
                      class="btn btn-link color-third mr-2"
                      @click="declineCompanyInvitation(invite)"
                    >
                      Decline
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="acceptCompanyInvitation(invite)"
                    >
                      Accept
                    </button>

                    <!-- <i class="fas fa-ban cursor-pointer mr-3" title="Decline invitation"></i> -->
                    <!-- <i class="fas fa-check color-first cursor-pointer" @click="acceptCompanyInvitation(invite)" title="Accept invitation"></i> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- PROFESSIONAL - REGISTER COMPANY -->
          <div
            class="col-12 col-md-6"
            v-if="
              (user.role === 'Professional' &&
                user.company.length === 0 &&
                companyInvites.length === 0) ||
              (user.role === 'Professional' && user.company === null)
            "
          >
            <div class="widget">
              <div class="widget-header">
                <h4>Guide</h4>
                <div class="widget-navigation">
                  <!-- <router-link :to="'/company/new'" class="widget-nav"><i class="fas fa-plus"></i>Register</router-link> -->
                </div>
              </div>
              <div class="widget-body">
                <div class="mb-3">
                  <h2>Next step</h2>
                  <p>
                    To use the Excelerate platform you have to register your
                    company.<br />
                    If your company is already using Excelerate, they can send
                    you an invite to join the company group.
                  </p>
                  <div class="text-center">
                    <router-link
                      :to="'/company/new'"
                      class="btn btn-primary btn-lg btn-block mt-2"
                      >Register your company</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- STUDENT COLLABORATION WIDGET -->
          <div v-if="user.role === 'Student'" class="col-12 col-md-6">
            <div class="widget">
              <div class="widget-header sticky">
                <h4>Published collaborations</h4>
                <div class="widget-navigation">
                  <router-link :to="'/collaboration/my'" class="widget-nav"
                    ><i class="fas fa-hands-helping"></i>My
                    collaboration</router-link
                  >
                </div>
              </div>
              <div class="widget-body">
                <template v-if="collaborationsByUser.length">
                  <router-link
                    :to="'/collaborations/' + collab.id"
                    class="card"
                    v-for="(collab, index) in collaborationsByUser"
                    :key="index"
                  >
                    <div class="card-image">
                      <img
                        :src="
                          '/assets/institutions/' +
                          collab.collaboration.institution.id +
                          '.png'
                        "
                        class="card-img-top"
                        :title="collab.collaboration.institution.name"
                      />
                    </div>
                    <div class="card-body">
                      <div class="card-area">
                        <small>{{
                          cutText(collab.collaboration.area, 60)
                        }}</small>
                      </div>
                      <hr />
                      <h2 class="card-title">
                        {{ cutText(collab.collaboration.title, 100) }}
                      </h2>
                      <h6 class="card-subtitle">
                        {{ collab.collaboration.subtitle }}
                      </h6>
                      <p class="card-text">
                        {{ cutText(collab.collaboration.content, 300) }}
                      </p>
                      <div class="card-bottom">
                        <span>{{ collab.collaboration.degree }}</span
                        ><span class="divider">|</span>
                        <span>{{ collab.collaboration.targetSemester }}</span
                        ><span class="divider">|</span>
                        {{ collab.collaboration.members.length }} members
                        <!-- <span class="badge" v-if="collab.collaboration.members.includes(user.userId)">yours</span> -->
                        <span class="btn btn-link float-right">View</span>
                      </div>
                    </div>
                  </router-link>
                </template>

                <div class="" v-else>
                  <div class="mb-3">
                    <h2>Next step</h2>
                    <p>
                      Fill out the thesis title and description, save the draft
                      and invite your thesis group to join you here.<br />
                      <br />
                      Click ‘start collaborating’ to get going
                    </p>
                    <div class="text-center">
                      <router-link
                        :to="'/collaboration/new'"
                        class="btn btn-primary btn-block btn-lg"
                        >Start collaborating</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- PROFESSIONAL - OFFERS -->
          <!-- <div v-if="user.role === 'Professional' && user.company.length > 0 && companyOffers.length > 0" class="col-12 temp-widget">
                    <div class="widget">
                        <div class="widget-header">
                            <h4>Latest negotiations</h4>
                        </div>
                        <div class="widget-body">
                            <router-link :to="'/collaborations/' + offer.offer.collaborationId" v-for="(offer, index) in companyOffers" :key="index" class="erow row link d-flex">
                                <div class="col-1 align-self-center">
                                    <i class="fas fa-hands-helping" ></i>
                                </div>
                                <div class="col-5 align-self-center">
                                    Title
                                    <div class="headline">
                                        <span class="highlight">{{offer.offer.collaborationTitle}}</span>
                                    </div>
                                </div>
                                <div class="col-3 align-self-center">
                                    Status
                                    <div class="headline">
                                        Waiting for your accept
                                    </div>
                                </div>
                                <div class="col-3 align-self-center text-right">
                                    <span class="btn btn-link color-third">View</span>
                                </div>
                            </router-link>
                            <div class="text-center">
                                <router-link :to="'/companies/' + user.company" class="btn btn-primary">
                                    View all negotiations 
                                </router-link>
                            </div>

                        </div>
                    </div>
                </div> -->

          <div class="col-12 col-md-6">
            <!-- STUDENT COLLABORATION INVITES -->
            <div
              v-if="user.role === 'Student' && collaborationInvites.length > 0"
            >
              <div class="widget">
                <div class="widget-header">
                  <h4>Invites</h4>
                  <div class="widget-navigation">
                    <router-link :to="'/collaboration/my'" class="widget-nav"
                      ><i class="fas fa-link"></i>My collaborations</router-link
                    >
                  </div>
                </div>
                <div class="widget-body">
                  <router-link
                    :to="
                      '/collaborations/' +
                      invite.invites.collaborationId +
                      '#members'
                    "
                    v-for="(invite, index) in collaborationInvites"
                    :key="index"
                    class="erow row link d-flex"
                  >
                    <div class="col-1 align-self-center">
                      <i class="fas fa-envelope"></i>
                    </div>
                    <div class="col-4 align-self-center">
                      From
                      <div class="headline">{{ invite.invites.sender }}</div>
                    </div>
                    <div class="col-5 align-self-center">
                      Collaboration
                      <div class="headline">
                        {{ cutText(invite.invites.collaborationTitle, 40) }}
                      </div>
                    </div>
                    <div class="col-2 align-self-center">
                      <span class="btn btn-link">View</span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="widget">
              <div class="widget-header">
                <h4>Lastest topics</h4>
                <div class="widget-navigation">
                  <!-- <router-link :to="'/collaborations/'" class="widget-nav color-third"><i class="fas fa-search"></i>Find collaborations</router-link> -->
                </div>
              </div>
              <div class="widget-body">
                <router-link
                  :to="'/collaborations/' + collab.id"
                  v-for="(collab, index) in lastestCollaborations"
                  :key="index"
                >
                  <div class="erow">
                    <div class="row">
                      <div class="col-12 col-md-4">
                        <img
                          :src="
                            '/assets/institutions/' +
                            collab.institution.id +
                            '.png'
                          "
                          class="img-fluid"
                        />

                        <!-- <div class="mt-4">
                                            <i class="fas fa-user-graduate"></i> 3 students
                                        </div> -->
                      </div>
                      <div class="col-12 col-md-8">
                        <div class="headline mb-2">
                          {{ collab.title }}
                        </div>
                        <span class="btn btn-link color-third btn-sm"
                          >View collaboration</span
                        >
                      </div>
                    </div>
                  </div>
                </router-link>

                <div class="text-center">
                  <router-link to="/collaborations/" class="btn btn-secondary">
                    Find collaborations
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
import { mapState } from 'vuex';
import { db } from '../../firebase';
import MasterHeader from '@/components/MasterHeader.vue';
import SignUpModal from '../../components/signUpModal.vue';

export default {
  data: function () {
    return {
      email: '',
      lastestCollaborations: '',
    };
  },
  metaInfo: {
    title: 'Your Dashboard',
    meta: [
      {
        name: 'description',
        content:
          'A digital platform that connects students and companies via thesis collaboration matching.',
      },
      { property: 'og:title', content: 'Your Dashboard' },
      {
        property: 'og:description',
        content:
          'A digital platform that connects students and companies via thesis collaboration matching.',
      },
    ],
  },
  components: {
    MasterHeader,
    SignUpModal,
  },
  created() {
    //todo computed
    this.$store.dispatch('getCollaborationByUserId', this.user); //todo - only do this if needed?
    this.$store.dispatch('getCollaborationInvitesByUserId');
    this.$store.dispatch('getCompanyInvitesByUserId');
    this.$store.dispatch('getCompanyOffersById', this.user);
    // this.$store.dispatch('getCompanyCollaborationsById', this.user.company);
  },
  firestore() {
    return {
      lastestCollaborations: db
        .collection('collaborations')
        .where('status', '==', 'published')
        .orderBy('createdDate', 'desc')
        .limit(4),
    };
  },
  computed: {
    ...mapState(['user']),
    hasNewSignupFlow() {
      const vm = this;
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false;
      const isStudent = vm.$store.getters.role === 'Student';
      if (!isValid && isStudent) {
        localStorage.setItem('notSignedUp', 1);
      }
      if (isValid) {
        localStorage.removeItem('notSignedUp');
      }
      return true;
    },
    collaborationsByUser: function () {
      console.log(this.user);
      const collection = this.$store.state.collaboration.usersCollaborations;
      var result = [];

      result = collection.filter((item) => {
        return item.collaboration.status === 'published';
      });

      return result;
    },
    collaborationInvites: function () {
      return this.$store.state.collaboration.collaborationInvites;
    },
    companyInvites: function () {
      return this.$store.state.company.usersCompanyInvites;
    },
    companyOffers: function () {
      return this.$store.state.company.companyOffers;
    },
    // companyCollaborations: function() {
    //     return this.$store.state.company.companyCollaborations;
    // }
  },
  methods: {
    acceptCompanyInvitation(payload) {
      var vm = this;
      //TODO: MAIL 'Professional - New professional Member Joined' -> senderEmail @param1: user.firstName, param2: user.lastName

      const templateData = {
        toEmail: payload.invites.senderEmail,
        firstName: vm.user.firstName,
        lastName: vm.user.lastName,
        param1: payload.invites.sender,
        param2: payload.invites.companyId,
        template: 'Professional - New professional Member Joined',
      };
      vm.$store.dispatch('sendMail', templateData);

      this.$store.dispatch('acceptCompanyInvitation', payload);
    },
    declineCompanyInvitation(payload) {
      var vm = this;
      //TODO: MAIL 'Professional - Declined invitation'
      //receiver email: senderEmail
      //firstName (this user), lastName (this user), param1: sender

      const templateData = {
        toEmail: payload.invites.senderEmail,
        firstName: vm.user.firstName,
        lastName: vm.user.lastName,
        param1: payload.invites.sender,
        param2: payload.invites.companyId,
        template: 'Professional - Declined invitation',
      };
      vm.$store.dispatch('sendMail', templateData);

      this.$store.dispatch('deleteUsersCompanyInviteById', payload);
    },
    cutText: function (text, characters) {
      if (typeof text !== typeof undefined) {
        return text.length > characters
          ? text.substring(0, characters) + '...'
          : text;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  .temp-widget {
    margin-bottom: 10px;

    .widget-content {
      border-radius: 7px;
      color: #333;
      background: #ffffff;
      border: 1px solid #f1f1f1;
      box-shadow: 3px 3px 6px #f1f1f1;
      padding: 20px;
      font-size: 20px;

      margin-bottom: 10px;
    }
  }
}
</style>
